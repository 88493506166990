export default class Responsive {
  static BREAKPOINTS = {
    xsm: 360,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
    mega: 2000,
  };

  static QUERIES = {
    short: '(max-height: 600px)', // i.e. iPhone SE
    xsm: `(min-width: ${Responsive.BREAKPOINTS.xsm}px)`,
    lessThanXsm: `(max-width: ${Responsive.BREAKPOINTS.xsm - 1}px)`,
    sm: `(min-width: ${Responsive.BREAKPOINTS.sm}px)`,
    lessThanSm: `(max-width: ${Responsive.BREAKPOINTS.sm - 1}px)`,
    md: `(min-width: ${Responsive.BREAKPOINTS.md}px)`,
    lessThanMd: `(max-width: ${Responsive.BREAKPOINTS.md - 1}px)`,
    lg: `(min-width: ${Responsive.BREAKPOINTS.lg}px)`,
    lessThanLg: `(max-width: ${Responsive.BREAKPOINTS.lg - 1}px)`,
    xl: `(min-width: ${Responsive.BREAKPOINTS.xl}px)`,
    lessThanXl: `(max-width: ${Responsive.BREAKPOINTS.xl - 1}px)`,
    xxl: `(min-width: ${Responsive.BREAKPOINTS.xxl}px)`,
    lessThanXxl: `(max-width: ${Responsive.BREAKPOINTS.xxl - 1}px)`,
    mega: `(min-width: ${Responsive.BREAKPOINTS.mega}px)`,
  };

  static DENSITY = {
    x2: '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx)',
    x3: '(-webkit-min-device-pixel-ratio: 3), (min-resolution: 288dpi), (min-resolution: 3dppx)',
  };

  static short(styles) {
    return {
      [`@media ${Responsive.QUERIES.short}`]: styles,
    };
  }

  static matches(query) {
    if (typeof window !== 'undefined') {
      return window.matchMedia(query).matches;
    }
  }

  static lessThanXsm(styles) {
    return {
      [`@media ${Responsive.QUERIES.lessThanXsm}`]: styles,
    };
  }

  static xsm(styles) {
    return {
      [`@media ${Responsive.QUERIES.xsm}`]: styles,
    };
  }

  static lessThanSm(styles) {
    return {
      [`@media ${Responsive.QUERIES.lessThanSm}`]: styles,
    };
  }

  static sm(styles) {
    return {
      [`@media ${Responsive.QUERIES.sm}`]: styles,
    };
  }

  static lessThanMd(styles) {
    return {
      [`@media ${Responsive.QUERIES.lessThanMd}`]: styles,
    };
  }

  static md(styles) {
    return {
      [`@media ${Responsive.QUERIES.md}`]: styles,
    };
  }

  static lessThanLg(styles) {
    return {
      [`@media ${Responsive.QUERIES.lessThanLg}`]: styles,
    };
  }

  static lg(styles) {
    return {
      [`@media ${Responsive.QUERIES.lg}`]: styles,
    };
  }

  static lessThanXl(styles) {
    return {
      [`@media ${Responsive.QUERIES.lessThanXl}`]: styles,
    };
  }

  static xl(styles) {
    return {
      [`@media ${Responsive.QUERIES.xl}`]: styles,
    };
  }

  static xxl(styles) {
    return {
      [`@media ${Responsive.QUERIES.xxl}`]: styles,
    };
  }

  static mega(styles) {
    return {
      [`@media ${Responsive.QUERIES.mega}`]: styles,
    };
  }

  static x2(styles) {
    return {
      [`@media ${Responsive.DENSITY.x2}`]: styles,
    };
  }

  static x3(styles) {
    return {
      [`@media ${Responsive.DENSITY.x3}`]: styles,
    };
  }
}
