import PropTypes from '@root/vendor/prop-types';
import React, { Component } from '@root/vendor/react';
import banner from '@root/trymyles.com/src/assets/myles-earn-free-roadside-assistance.jpg';
import favicon from '@root/trymyles.com/src/assets/icons/favicon.ico';
import { Helmet } from '@root/website-tooling/react-helmet';

export default class Seo extends Component {
  static defaultProps = {
    lang: 'en',
  };

  static propTypes = {
    author: PropTypes.string,
    description: PropTypes.string,
    keywords: PropTypes.string,
    lang: PropTypes.string,
    title: PropTypes.string,
  };

  render() {
    return (
      <Helmet
        htmlAttributes={{
          lang: this.props.lang,
        }}
        link={[
          {
            rel: 'shortcut icon',
            type: 'image/png',
            href: `${favicon}`,
          },
        ]}
        meta={[
          {
            name: 'description',
            content: this.props.description,
          },
          {
            name: 'keywords',
            content: this.props.keywords,
          },
          {
            name: 'twitter:title',
            content: this.props.title,
          },
          {
            name: 'twitter:description',
            content: this.props.description,
          },
          {
            property: 'og:title',
            content: this.props.title,
          },
          {
            property: 'og:description',
            content: this.props.description,
          },
          {
            property: 'og:type',
            content: 'website',
          },
          {
            property: 'og:image',
            content: banner,
          },
          {
            property: 'twitter:image',
            content: banner,
          },
          {
            property: 'image',
            content: banner,
          },
        ]}
        title={this.props.title}
        titleTemplate={'%s'}
      />
    );
  }
}
